function initInputGroupEvents() {
  const inputGroup = document.querySelectorAll('.hePdpStuInsToggleBtnGroup input[type="radio"]');

  function moveFocus(currentIndex, direction) {
    const newIndex = (currentIndex + direction + inputGroup.length) % inputGroup.length;
    inputGroup[newIndex].focus();
  }

  inputGroup.forEach((radio, index) => {
    radio.addEventListener('keydown', (event) => {
      
      if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
        event.preventDefault();
        // Move focus to the next radio
        moveFocus(index, 1);
      }

      if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
        event.preventDefault();
        // Move focus to the previous radio
        moveFocus(index, -1);
      }
      
      // Handle Space or Enter to select the radio button
      if (event.key === ' ' || event.key === 'Enter') {
        radio.checked = true; // Select the focused radio button
      }

      // Handle Tab to move focus
      if (event.key === 'Tab') {
        // Move focus to the next or previous focusable element
        if (event.shiftKey) {
          // Shift + Tab: Move focus to the previous element
          const prevIndex = (index - 1 + inputGroup.length) % inputGroup.length;
          if (prevIndex === inputGroup.length - 1) {
            // No previous radio button, let default behavior handle it
            return;
          }
          event.preventDefault();
          inputGroup[prevIndex].focus();
        } else {
          // Tab: Move focus to the next element
          const nextIndex = (index + 1) % inputGroup.length;
          if (nextIndex === 0) {
            // No next radio button, let default behavior handle it
            return;
          }
          event.preventDefault();
          inputGroup[nextIndex].focus();
        }
      }
    });
  });
};

if (document.readyState !== 'loading') {
  initInputGroupEvents();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    initInputGroupEvents();
  });
}
